import React from "react"
import { graphql, PageProps } from "gatsby"
import Page from "@app/components/Account/Activate/AccountActivate"

export type Props = PageProps<GatsbyTypes.PageAccountActivateQuery, GatsbyTypes.PageAccountActivateQueryVariables>

export const query = graphql`
  query PageAccountActivate {
    page: sanityPageAccountActivate {
      title
      url
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
