import React, { useState } from "react"
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  IconButton,
  Stack,
  Box,
} from "@chakra-ui/react"
import { BiShow, BiHide } from "react-icons/bi"
import { useCustomerAccount } from "@app/hooks/useCustomer"
import { graphql, useStaticQuery } from "gatsby"

const AccountActivateForm: React.FC = () => {
  const [show, setShow] = useState(false)
  const { handleActivateSubmit, handleChange, data, loading, errors } = useCustomerAccount()

  const { additionalContent } = useStaticQuery<GatsbyTypes.StaticActivatePageAdditionalContentQuery>(graphql`
    query StaticActivatePageAdditionalContent {
      additionalContent: sanityPageAccountActivate {
        additionalSubmitButton
      }
    }
  `)

  const { additionalSubmitButton } = additionalContent || {}

  const handleShow = () => setShow(!show)

  return (
    <Box as="form" onSubmit={handleActivateSubmit} mb={8}>
      <Stack spacing={5}>
        <FormControl id="password" value={data?.password} onChange={handleChange} isRequired>
          <FormLabel>New Password</FormLabel>
          <InputGroup size="md">
            <Input name="password" type={show ? "text" : "password"} />
            <InputRightElement>
              <IconButton
                variant="ghost"
                onClick={handleShow}
                aria-label={`${show ? "Show" : "Hide"} password`}
                size="md"
                icon={show ? <BiHide /> : <BiShow />}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>

        <FormControl id="passwordConfirmation" value={data?.passwordConfirmation} onChange={handleChange} isRequired>
          <FormLabel>Confirm Password</FormLabel>
          <InputGroup size="md">
            <Input name="passwordConfirmation" type={show ? "text" : "password"} />
            <InputRightElement>
              <IconButton
                variant="ghost"
                onClick={handleShow}
                aria-label={`${show ? "Show" : "Hide"} password`}
                size="md"
                icon={show ? <BiHide /> : <BiShow />}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>

        <Button type="submit" isDisabled={loading} isLoading={loading}>
          {additionalSubmitButton}
        </Button>

        {errors?.length > 0 && (
          <FormControl id="error" isInvalid>
            {errors?.map((error, index) => (
              <FormErrorMessage key={index}>{error}</FormErrorMessage>
            ))}
          </FormControl>
        )}
      </Stack>
    </Box>
  )
}

export default React.memo(AccountActivateForm)
